@import "~less-variables";

.bar {
    display: flex;
    align-items: center;
    padding: 13px 15px;
    justify-content: space-between;

    .checkbox {
        display: flex;
        align-items: center;
        height: 31px;
        input[type="checkbox"] {
            margin: 0 !important;
        }
    }
    .selection {
        display: flex;
        align-items: center;

        > * {
            margin-right: 15px;
        }
    }
}

.barTableHeader {
    position: sticky;
    top: 60px;
    overflow: auto;
    border-bottom: 1px solid @color-divider;
    background-color: #fff;
    z-index: @zindex-table-header-sticky;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    min-height: 65px;
}
