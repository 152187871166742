@import "~less-variables";

.text-truncate() {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item {
    display: flex;
    align-items: center;
}

.itemImage {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-right: 10px;
}

.itemBody {
    flex: 1;
    overflow: hidden;
}

.itemTitle {
    font-size: 14px;
    font-weight: @font-weight-semi;
    .text-truncate();
}

.itemMeta {
    color: @mute-color;
    font-size: 13px;
    .text-truncate();
}
